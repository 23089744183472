import React from 'react';
import Button from '@kyruus/button';
import { injectIntl } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';

import messages from './messages';

import {
  AvailabilityControlsContainer,
  ButtonsContainer,
  StyledChip,
  SelectedAppointmentContainer,
  AvailabilityControlsHeadingOff,
  AvailabilityControlsHeadingOn,
  FlexContainer
} from './styles';
import { NEW_PATIENT } from '../../utils/constants';

const AvailabilityControls = ({
  selectedAppointment,
  handleToggle,
  handleEdit,
  intl
}) => {
  const isOn = Boolean(selectedAppointment);

  const buttons = [];

  if (isOn) {
    const editBtn = (
      <Button
        mode="ghost"
        onClick={handleEdit}
        data-testid="AvailabilityControls--edit"
        aria-label={kyruusFormatMessage(
          intl,
          messages.editGlobalAvailabilityBtnTextAriaLabel
        )}
      >
        <KyruusFormattedMessage {...messages.editGlobalAvailabilityBtnText} />
      </Button>
    );

    const offBtn = (
      <Button
        mode="secondary"
        onClick={handleToggle}
        data-testid="AvailabilityControls--toggle"
        aria-label={kyruusFormatMessage(
          intl,
          messages.hideGlobalAvailabilityBtnTextAriaLabel
        )}
      >
        <KyruusFormattedMessage {...messages.hideGlobalAvailabilityBtnText} />
      </Button>
    );

    buttons.push(editBtn, offBtn);
  }

  if (!isOn) {
    const onBtn = (
      <Button
        mode="ghost"
        onClick={handleToggle}
        data-testid="AvailabilityControls--toggle"
        aria-label={kyruusFormatMessage(
          intl,
          messages.showGlobalAvailabilityBtnTextAriaLabel
        )}
      >
        <KyruusFormattedMessage {...messages.showGlobalAvailabilityBtnText} />
      </Button>
    );

    buttons.push(onBtn);
  }

  return (
    <AvailabilityControlsContainer isOn={isOn}>
      {isOn ? (
        <>
          <AvailabilityControlsHeadingOn>
            <KyruusFormattedMessage
              {...messages.globalAvailControlsHeaderTextOn}
            />
          </AvailabilityControlsHeadingOn>
          <FlexContainer>
            <SelectedAppointmentContainer>
              <StyledChip
                label={
                  selectedAppointment.patientRel === NEW_PATIENT ? (
                    <KyruusFormattedMessage
                      {...messages.patientRelBtnTextNew}
                    />
                  ) : (
                    <KyruusFormattedMessage
                      {...messages.patientRelBtnTextEstablished}
                    />
                  )
                }
                data-testid="AvailabilityControls--patientRel"
              />
              <StyledChip
                label={selectedAppointment.purpose}
                data-testid="AvailabilityControls--apptPurpose"
              />
            </SelectedAppointmentContainer>
            <ButtonsContainer>{buttons}</ButtonsContainer>
          </FlexContainer>
        </>
      ) : (
        <ButtonsContainer>{buttons}</ButtonsContainer>
      )}

      {!isOn ? (
        <AvailabilityControlsHeadingOff>
          <KyruusFormattedMessage
            {...messages.globalAvailControlsHeaderTextOff}
          />
        </AvailabilityControlsHeadingOff>
      ) : null}
    </AvailabilityControlsContainer>
  );
};

export default injectIntl(AvailabilityControls);
