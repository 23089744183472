import React from 'react';
import Banner from '@kyruus/banner';
import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';
import { defineMessages } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import { REDIRECT_ERROR } from '../utils/constants';

const messages = defineMessages({
  errorMessageRedirectGeneral: {
    id: 'error.message.redirect.general',
    description:
      'Error message when we are not able to redirect the user to the appropriate step in the direct book flow following a general error',
    defaultMessage: 'We had trouble processing your request. Please try again.'
  },
  errorMessageRedirectDeeplinkingThirdPartyLogin: {
    id: 'error.message.redirect.deeplinking.third_party_login',
    description:
      'Error message when we are not able to redirect the user to the appropriate step in the direct book flow following a third-party login error',
    defaultMessage:
      'We had trouble logging in and need to restart. You can start a new search and finish booking your appointment.'
  }
});

const Container = styled('div')`
  > div {
    border-radius: ${fromTheme('border_radius')};
    margin-top: ${fromTheme('spacing_large')};
  }
`;

/**
 * Error message to be displayed as the result of a redirect error.
 *
 * @example
 * Failing to redirect the user to their previous spot in the direct book flow following a third-party login attempt
 * due to an invalid deep-linking URL
 *
 * @param {Object} routeProps Router props object containing the `match`, `history`, and `location`
 * @returns an error banner
 */
export default function RedirectErrorMessage({ location } = {}) {
  let message;
  switch (location.state.redirect_error) {
    case REDIRECT_ERROR.DIRECT_BOOK_DEEPLINKING: {
      message = messages.errorMessageRedirectDeeplinkingThirdPartyLogin;
      break;
    }
    default: {
      message = messages.errorMessageRedirectGeneral;
    }
  }
  return (
    <Container data-testid="RedirectErrorMessage">
      <Banner type="alert" icon="!">
        <KyruusFormattedMessage {...message} />
      </Banner>
    </Container>
  );
}
